import {createRouter, createWebHistory} from 'vue-router';


const routes = [
    {
        path: '/login',
        name: 'LoginPage',
        component: require('@/views/Login').default,
        meta: {
            forGuests: true
        }
    },

    {
        path: '/',
        name: 'indexPage', component: require('@/views/Index').default,
        meta: {
            forUsers: true,
            permission: 'read-cr-announcement'
        }
    },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;

