import { createApp } from 'vue';
import App from './App.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import router from './router';
import axios from 'axios';
import moment from 'moment';
import store from './store';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
});

const app = createApp(App);
window.axios = axios;
app.use(vuetify);
app.use(store);
app.use(router);
app.use(moment);

router.beforeEach(

    (to, from, next) => {
        if(to.path !== "/LoginPage" && localStorage.getItem("access_token") == null){
            store.dispatch('storeRedirectUrl',to.path);
        }
        if(to.matched.some(record => record.name == 'login')) {
            if(!localStorage.getItem('access_token')) {
                let user = JSON.parse(localStorage.getItem('auth_user'));
                if(user != null)
                {
                    next({
                        path: '/',
                    })
                }
                else {
                    next();
                }
            } else {
                next();
            }
        } else if(to.matched.some(record => record.meta.forGuests)){
            next();
        } else if(to.matched.some(record => record.meta.forUsers)) {
            if(localStorage.getItem('access_token') === null) {
                next({ name: 'LoginPage' })
            } else {
                if(!store.getters.get_access_token) {
                    store.dispatch('storeAccessToken', localStorage.getItem('access_token'));
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.get_access_token;

                    store.dispatch('storePermissions', localStorage.getItem('permissions'));
                    store.dispatch('storeUser', JSON.parse(localStorage.getItem('auth_user')))
                }
                let permissions = store.getters.get_permissions;

                if(permissions.indexOf(to.meta.permission) == -1) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('expiration')
                    localStorage.removeItem('permissions')
                    localStorage.removeItem('auth_user')
                    store.dispatch('removeAccessToken')

                    router.push({ name: 'LoginPage', query: { error: 'Session Expired' } })
                }
                next();
            }
        } else next();
    }
);

window.axios.interceptors.response.use(null, function(error) {
    switch(error.response.status) {
        case 401:
            localStorage.removeItem('access_token');
            localStorage.removeItem('expiration');
            localStorage.removeItem('permissions')
            localStorage.removeItem('auth_user');
            store.dispatch("removeAccessToken");
            store.dispatch("removePermissions");
            // this.$router.push({name: "login"});
            router.push({ name: 'LoginPage', query: {error: 'Session Expired'}});
            break;
        case 403:
            localStorage.removeItem('access_token')
            localStorage.removeItem('expiration')
            localStorage.removeItem('permissions')
            localStorage.removeItem('auth_user')
            store.dispatch("removeAccessToken");
            store.dispatch("removePermissions");
            router.push({ name: 'LoginPage', query: {error: 'Session Expired'}});
            break;
        case 404:
            //
            break;
        case 429:
            //
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

app.mixin({
    methods: {
        startLoading() {
            store.dispatch('startLoader');
        },
        stopLoading() {
            store.dispatch('stopLoader');
        },
        moment_timezone(date_time = new Date()) {
            return moment(date_time).tz('Asia/Kolkata');
        },
        storage_path() {
            return `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_STORAGE_PATH}/`;
        },
        checkPermission(permission) {
            return store.getters.get_permissions.indexOf(permission) > -1;
        }
    }
});

app.mount('#app');
