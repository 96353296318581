<template>
  <div v-if="get_access_token">
    <v-navigation-drawer
        app
        v-model="drawer"
        fixed
        expand-on-hover
        id="core-navigation-drawer"
        width="260"
        v-bind="$attrs"
        class="accent-4"
        dark
        :temporary="!display.mdAndUp">
      <v-list-item>
        <v-list-item-title v-if="!mini">
          <v-img
              :aspect-ratio="1"
              class="bg-white"
              :src="require('../assets/images/company_logo.png')"
              width="91px"
          ></v-img>
        </v-list-item-title>

        <v-list-item-title v-else>
          <v-img
              :aspect-ratio="1"
              class="bg-white"
              :src="require('../assets/images/company_logo.png')"
              width="35px"
          ></v-img>
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
            v-for="(item, i) in headers"
            :key="i"
            :value="item"
            color="primary"
            rounded="shaped"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main style="padding-bottom: 0px !important; padding-top: 0px; background-color: #ff5e5c">
      <v-app-bar app>
        <template v-slot:prepend>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </template>
        <v-spacer></v-spacer>
        <div style="display:flex; height: 64px; justify-content: flex-start; align-items: center;">
        <span class="black--text" > {{"Hie "}}{{ username + " !   "}}</span>
        </div>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-form @submit.prevent="logout()" ref="form">
                  <v-btn type="submit" :loading="logout_loader" text
                  ><span>Logout</span></v-btn>
                </v-form>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-main>
  </div>
  <div v-else style="display: none"></div>
</template>

<script>
import { ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import axios from 'axios';
import {mapGetters} from "vuex";

export default {
  name: "Navigation-page",

  setup() {
    const display = useDisplay();
    const drawer = ref(display.mdAndUp);

    // Watch for changes in display.mdAndUp and adjust drawer state
    watch(() => display.mdAndUp, (newValue) => {
      drawer.value = newValue;
    });

    return {
      display,
      drawer
    };
  },
  computed: {
    ...mapGetters(["get_access_token", "get_user"]),
    // eslint-disable-next-line vue/no-dupe-keys
    // drawer: {
    // get() {
    //   return this.$store.state.drawer
    // },
    // set(val) {
    //   this.$store.commit('SET_DRAWER', val)
    // },
    // },

  },
  data() {
    return {
      mini: false,
      username: '',
      logout_loader: false,
      headers: [
        {
          icon: "mdi-selection-search",
          text: "Train Status",
          name: "train_status",
          to: "/",
        },
      ]
    }
  },
  methods: {
    getInitials() {
      // console.warn(this.$store.getters.get_user.length);
      // console.warn("adasd");
      if(localStorage.getItem("auth_user") != null){
         let return_string =
            this.$store.getters.get_user.first_name.charAt(0).toUpperCase() + this.$store.getters.get_user.first_name.slice(1) +
            " " +
            this.$store.getters.get_user.last_name.charAt(0).toUpperCase() +
             this.$store.getters.get_user.last_name.slice(1);
        this.username = return_string;
      }

    },
    logout() {
      this.logout_loader = true;
      this.startLoading();
      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/logout`)
          .then((response) => {
            if (response.status == 1) {
              localStorage.clear();
              this.$store.dispatch("removeAccessToken");
              this.$store.dispatch("removePermissions");
              this.logout_loader = false;
              this.stopLoading();
              this.$router.push({ path: '/login' });
            } else {
              localStorage.clear();
              this.$store.dispatch("removeAccessToken");
              this.logout_loader = false;
              this.stopLoading();
              this.$router.push({ path: '/login' });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.stopLoading();
            this.$router.push({ path: '/login' });
            localStorage.clear();
            this.$store.dispatch("removeAccessToken");
            this.logout_loader = false;
          });
    }
  },
  mounted() {
    this.getInitials();
  }
}
</script>

<style scoped>
.v-navigation-drawer .v-list-item--active {
  /*box-shadow: inset 0px 0 3px 0 #ff5252 !important;*/
  background: linear-gradient(90deg, #ff5e5c 0%, #ff9233 100%) !important;
}
</style>
