<template>
<div>
<v-footer app>
  <div class="px-4 py-2 bg-white text-center w-100">
    {{ new Date().getFullYear() }} — <strong>Design and Developed by - CDP Yatri Pvt. Ltd.</strong>
  </div>
</v-footer>
</div>
</template>

<script>
export default {
  name: "FooterBar"
}
</script>

<style scoped>

</style>
