<template>
  <div id="app" style="margin-top: 0px !important;">
    <v-app id="inspire" style="margin-top: 0px !important;">
      <div v-if="get_access_token">
        <Navigation></Navigation>

      </div>
      <div v-else style="display:none"></div>

      <v-container
          fluid
          grid-list-md
          px-0 style="margin-top: 30px; padding: 0;">
        <v-main style="padding-top: 20px !important;">
          <v-container>
            <router-view></router-view>
          </v-container>
        </v-main>

      <FooterBar></FooterBar>

      </v-container>
    </v-app>

  </div>
</template>

<script>
import Navigation from "@/components/NavigationBar";
import FooterBar from "@/components/FooterBar";
import {mapGetters} from "vuex";


export default {
  name: 'App',
  components: {Navigation, FooterBar},
  computed: {
    ...mapGetters(["get_access_token", "get_user"]),
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
