import { createStore } from 'vuex';

export default createStore({
    state: {
        global_loader: false,
        access_token: '',
        permissions: [],
        user: {
            roles: []
        },
        redirect_url: '',
    },
    getters: {
        get_access_token: (state) => state.access_token,
        get_permissions: (state) => state.permissions,
        get_user: (state) => state.user,
        show_global_loader: (state) => state.global_loader,
    },
    mutations: {
        storeAccessToken(state, access_token) {
            state.access_token = access_token;
        },
        removeAccessToken(state) {
            state.access_token = '';
        },
        storePermissions(state, permissions) {
            state.permissions = permissions;
        },
        removePermissions(state) {
            state.permissions = [];
        },
        storeUser(state, user) {
            state.user = user;
        },
        removeUser(state) {
            state.user = {
                roles: []
            };
        },
        storeRedirectUrl(state, redirect_url) {
            state.redirect_url = redirect_url;
        },
        updateLoaderState(state, status) {
            state.global_loader = status;
        },
    },
    actions: {
        startLoader({ commit }) {
            return new Promise((resolve) => {
                commit('updateLoaderState', true);
                resolve();
            });
        },
        stopLoader({ commit }) {
            return new Promise((resolve) => {
                commit('updateLoaderState', false);
                resolve();
            });
        },
        storeAccessToken({ commit }, access_token) {
            return new Promise((resolve) => {
                commit('storeAccessToken', access_token);
                resolve();
            });
        },
        removeAccessToken({ commit }) {
            return new Promise((resolve) => {
                commit('removeAccessToken');
                resolve();
            });
        },
        storePermissions({ commit }, permissions) {
            return new Promise((resolve) => {
                let permissions_arr = JSON.parse(permissions);
                commit('storePermissions', permissions_arr);
                resolve();
            });
        },
        removePermissions({ commit }) {
            return new Promise((resolve) => {
                commit('removePermissions');
                resolve();
            });
        },
        storeUser({ commit }, user) {
            return new Promise((resolve) => {
                commit('storeUser', user);
                resolve();
            });
        },
        removeUser({ commit }) {
            return new Promise((resolve) => {
                commit('removeUser');
                resolve();
            });
        },
        storeRedirectUrl({ commit }, redirect_url) {
            return new Promise((resolve) => {
                commit('storeRedirectUrl', redirect_url);
                resolve();
            });
        },
    }
});
