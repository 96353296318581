<template>
  <v-row justify="center" dense style="margin-top: 20px;">
    <v-col cols="12" md="6" sm="12" lg="6" sx="12">
      <v-card class="mx-auto" style=" border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      border: 2px groove rgba(182,179,179,0.36);"
              title="UP Trains"
              flat>
        <v-divider></v-divider>

        <template v-slot:text>
          <v-text-field clearable
                        label="Search"
                        prepend-inner-icon="mdi-magnify"
                        v-model="up_search"
                        variant="outlined" single-line>
          </v-text-field>
        </template>

        <v-divider></v-divider>
        <v-data-table
            :headers="headers"
            :items="up_train_list"
            item-key="id"
            :loading="loader"
            @update:options="options => {
              this.pagination = { ...this.pagination, ...options };
            }"
            loading-text="Loading... Please wait"
        >
          <template v-slot:loading>
            <v-skeleton-loader type="table-row@4"></v-skeleton-loader>
          </template>

          <template v-slot:[`item.number`]="{ item }">
            <div class="text-justify">{{ item.number }}
              <!--              {{ item.short_code}}{{")"}}-->
            </div>
          </template>

          <template v-slot:[`item.speed`]="{ item }">
            <div class="text-justify" v-if="item.speed == 'S'">{{ "S" }}</div>
            <div class="text-justify" v-else-if="item.speed == 'F'">{{ "F" }}</div>
          </template>

          <template v-slot:[`item.schedule_time`]="{ item }">
            <div class="text-justify">{{ item.schedule_time }}</div>
          </template>

          <template v-slot:[`item.platform_number`]="{ item }">
            <div class="text-justify">
              <span v-if="item.platform_number">{{ item.platform_number }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div class="text-justify">
              <v-chip :color="getColor(item)"
                      class="text-uppercase"
                      size="small"
                      label variant="outlined"
              >
                <span v-if="item.nearest_station_position == 1">{{ 'Arriving&nbsp;' }}</span>
                <span v-else-if="item.nearest_station_position == 0">{{ 'Left&nbsp;' }}</span>
                <span v-else-if="item.nearest_station_position == 2">{{ 'At&nbsp;' }}</span>
                <span v-else>{{ " - " }}</span>
                {{ item.station_name ? ` ${item.station_name}` : '' }}
              </v-chip>
            </div>
          </template>

          <template v-slot:[`item.eta`]="{ item }">
            <div class="text-justify">
              <span v-if="item.eim >= 0"> {{ item.eim }} {{ " mins" }}</span>
              <span v-else>{{ " - " }}</span></div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col cols="12" md="6" sm="12" lg="6" sx="12">
      <v-card class="mx-auto" style=" border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      border: 2px groove rgba(182,179,179,0.36);"
              title="DOWN Trains"
              flat>

        <v-divider></v-divider>
        <template v-slot:text>
          <v-text-field clearable
                        label="Search"
                        prepend-inner-icon="mdi-magnify"
                        v-model="down_search"
                        variant="outlined" single-line>
          </v-text-field>
        </template>

        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="down_train_list"
            item-key="id"
            :loading="loader"
            loading-text="Loading... Please wait"
            @update:options="options => {
              this.pagination = { ...this.pagination, ...options };
            }">

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@4"></v-skeleton-loader>
          </template>

          <template v-slot:[`item.number`]="{ item }">
            <div class="text-justify">{{ item.number }}{{ " (" }}{{ item.speed }}{{ ")" }}</div>
          </template>

          <template v-slot:[`item.schedule_time`]="{ item }">
            <div class="text-justify">{{ item.schedule_time }}</div>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div class="text-justify">

              <v-chip :color="getColor(item)"
                      class="text-uppercase"
                      size="small"
                      label>
                <span v-if="item.nearest_station_position == 1">{{ 'Arriving&nbsp;' }}</span>
                <span v-else-if="item.nearest_station_position == 0">{{ 'Left&nbsp;' }}</span>
                <span v-else-if="item.nearest_station_position == 2">{{ 'At&nbsp;' }}</span>
                <span v-else>{{ " - " }}</span>
                {{ item.station_name ? ` ${item.station_name}` : '' }}
              </v-chip>

            </div>
          </template>

          <template v-slot:[`item.eta`]="{ item }">
            <div class="text-justify">
              <span v-if="item.eim >= 0"> {{ item.eim }} {{ " mins" }}</span>
              <span v-else>{{ " - " }}</span>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-alert
        v-for="(bar, index) in snackbars"
        :key="bar.text + index"
        v-model="bar.visible"
        type="success"
        class="snackbar-alert"
        :value="bar.visible"
        outlined
        dense
        :style="{ position: 'fixed', top: `${16 + (index * 100)}px`, right: '16px', zIndex: 9999 }">
      <p>{{ bar.text }} {{ " is arriving at Dadar" }}</p>

      <template v-slot:append>
        <v-btn flat icon @click.stop="handleAlertClose(bar.text)" style="background-color: transparent !important;">
          <v-icon class="text-white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-alert>

    <!--    <v-snackbar-->
    <!--        v-for="(bar, index) in snackbars"-->
    <!--        :key="bar.text + index"-->
    <!--        v-model="bar.visible"-->
    <!--        color="success"-->
    <!--        right-->
    <!--        close-on-content-click-->
    <!--        position="top"-->
    <!--    >-->
    <!--      <div class="text-subtitle-1 pb-2">{{ bar.text }} {{ " is arriving at Dadar" }}</div>-->

    <!--      <template v-slot:actions>-->
    <!--        <v-btn-->
    <!--            color="indigo"-->
    <!--            @click="bar.visible = false">-->
    <!--          <v-icon>mdi-close</v-icon>-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--    </v-snackbar>-->

  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import surpriseSound from '@/assets/bell.mp3'

export default {
  name: "indexPage",
  data() {
    return {
      train_list: [],
      up_train_list: [],
      down_train_list: [],
      loader: false,
      headers: [
        {title: 'Train No', value: 'number', align: 'left'},
        {title: 'Speed', value: 'speed', align: 'left'},
        {title: 'Time', value: 'schedule_time', align: 'left'},
        {title: 'PF No.', value: 'platform_number', align: 'left'},
        {title: 'Status', value: 'status', align: 'left'},
        {title: 'ETA', value: 'eta', align: 'left'}
      ],
      live_train_data: [],
      eta_time: [],
      pagination: {
        page: 1, // Current page
        rowsPerPage: 10, // Number of rows per page
        sortBy: [], // Optional: For sorting
      },
      snackbar: true,
      snackbars: [],
      flag: 0,
      audio: null,
      up_search: '',
      down_search: '',
      up_search_flag: 0,
      initial_up_search: 0,
      initial_down_search: 0,
      station_id: '',
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getLiveLocation();
      },
      deep: true
    },
    up_search(val) {
      if (val && val.length > 1) {
        this.initial_up_search = 1;
        this.up_train_list = [];

        if (val.length == 5 && this.up_search_flag == 0) {
          this.train_list.forEach((item) => {
            if (item.number == val) {
              this.up_train_list.push(item);
            }
          });
          this.getLiveLocation();
          this.up_search_flag = 1;
        } else if (val.length !== 5) {
          this.up_search_flag = 0;
        }
      } else if (val == null || val.length === 0 || val == '') {
        if(this.up_search_flag == 1){
          this.upClearList();
          this.up_search_flag = 0;
        }else if(this.initial_up_search == 1){
          this.up_search_flag = 1;
          this.upClearList();
          this.up_search_flag = 0;
          this.initial_up_search = 0;
        }
      } else if(this.up_search_flag == 1){
        if (val == ''){
          this.upClearList();
          this.up_search_flag = 0;
        }
      }
    },
    down_search(val) {
      if (val && val.length > 1) {
        this.initial_down_search = 1;

        this.down_train_list = []; //Ashwini end
        if (val.length == 5 && this.down_search_flag == 0) {

          this.train_list.forEach((item) => {
            if (item.number == val) {
              this.down_train_list.push(item);
            }
          });
          this.getLiveLocation();
          this.down_search_flag = 1; // Ensure the function is called only once
        } else if (val.length !== 5) {
          this.down_search_flag = 0; // Reset flag when input length changes
        }
      } else if (val === null || val.length === 0 || val == '') {
        if(this.down_search_flag == 1){
          this.downClearList();
          this.down_search_flag = 0;
        }else if(this.initial_up_search == 1){
          this.down_search_flag = 1;
          this.downClearList();
          this.down_search_flag = 0;
          this.initial_down_search = 0;
        }
      }else if(this.down_search_flag == 1){
        if (val == ''){
          this.downClearList();
          this.down_search_flag = 0;
        }
      }
    },
  },
  methods: {
    initializeAudio() {
      if (!this.audio) {
        this.audio = new Audio(surpriseSound);
        this.audio.play().catch(error => {
          console.error('Audio initialization failed:', error);
        });
      }
    },
    playSound1() {
      if (this.audio) {
        this.audio.currentTime = 0;
        this.audio.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      }
    },
    playSound() {
      if (this.snackbars.length > 0) {
        this.snackbars.forEach((snackbar, index) => {
          if (snackbar.visible != false) {
            snackbar.visible = true;
            this.playSound1();
          } else if (snackbar.visible == false) {
            // console.warn("ashwini ------- " + index)
            this.snackbars.splice(index, 1);
          }
        });
      }
    },
    getTrainList() {
      this.loader = true;
      this.train_list = [];
      axios.get(`${process.env.VUE_APP_API_URL}/api/v1/station-wise-train-list`,
          {
            headers: {
              'app-type': 'yatri-web',
              'api-key': process.env.VUE_APP_API_KEY
            },
            params: {
              'station_id': this.station_id,
              'line_id': [this.$store.getters.get_user.station_data.line_id]
            }
          }).then((response) => {
        if (response.data.status == 1) {
          this.train_list = response.data.data;
          this.getRefreshTrainList(this.train_list);
        } else {
          this.loader = false;
        }
      });
    },
    getColor(item) {
      if (item.nearest_station_position == 2) return 'red'
      else if (item.nearest_station_position == 1) return "orange"
      else if (item.nearest_station_position == 0) return 'green'
      else return '#FFF59D'
    },
    getLiveLocation() {
      if (localStorage.getItem("access_token") != null) {
        if (this.up_train_list.length > 0) {
          // console.warn("Live Location " + moment().format('H:m:s') + "-----" + this.up_train_list.length);

          this.live_train_data = [];

          if(this.up_train_list.length == 1){
            this.live_train_data.push(this.up_train_list[0].number);

            const start = (this.pagination.page - 1) * this.pagination.rowsPerPage;
            const end = start + this.pagination.rowsPerPage;

            const currentPageDownTrains = this.down_train_list.slice(start, end);

            currentPageDownTrains.forEach((item) => {
              this.live_train_data.push(item.number);
            });
          }else if(this.down_train_list.length == 1){
            this.live_train_data.push(this.down_train_list[0].number);

            const start = (this.pagination.page - 1) * this.pagination.rowsPerPage;
            const end = start + this.pagination.rowsPerPage;
            const currentPageUpTrains = this.up_train_list.slice(start, end);

            currentPageUpTrains.forEach((item) => {
              this.live_train_data.push(item.number);
            });
          }else{
            const start = (this.pagination.page - 1) * this.pagination.rowsPerPage;
            const end = start + this.pagination.rowsPerPage;

            const currentPageUpTrains = this.up_train_list.slice(start, end);
            const currentPageDownTrains = this.down_train_list.slice(start, end);

            currentPageUpTrains.forEach((item) => {
              this.live_train_data.push(item.number);
            });

            currentPageDownTrains.forEach((item) => {
              this.live_train_data.push(item.number);
            });
          }

          axios.get(`${process.env.VUE_APP_API_URL}/api/v1/get-live-location-ctrlroom`,
              {params: {'train_numbers': this.live_train_data}})
              .then((response) => {
                if (response.data.status == 1) {
                  this.live_location = response.data.live_locations;

                  this.up_train_list = this.up_train_list.map(item1 => {
                    const item2 = this.live_location.find(item => item.local_train_number === item1.number);
                    return {
                      ...item1,
                      ...item2
                    };
                  });
                  this.down_train_list = this.down_train_list.map(item1 => {
                    const item2 = this.live_location.find(item => item.local_train_number === item1.number);
                    return {
                      ...item1,
                      ...item2
                    };
                  });


                  this.up_train_list.forEach((item2) => {
                    if (item2) {
                      // localStorage.getItem('station_id')
                      if (item2.nearest_station_id == this.station_id
                          && item2.nearest_station_position == 1) {

                        const existingAlert = this.snackbars.find(alert => alert.text === item2.local_train_number);
                        if (!existingAlert) {
                          this.snackbars.push({text: item2.local_train_number, visible: true});
                        }
                      }
                    }
                  });

                  this.down_train_list.forEach((item2) => {
                    if (item2) {
                      // localStorage.getItem('station_id')
                      if (item2.nearest_station_id == this.station_id
                          && item2.nearest_station_position == 1) {

                        const existingAlert1 = this.snackbars.find(alert => alert.text === item2.local_train_number);

                        if (!existingAlert1) {
                          this.snackbars.push({text: item2.local_train_number, visible: true});
                        }
                      }
                    }
                  })
                  this.getETA();
                }
                this.playSound();
              });
        }
      }
    },
    getETA() {
      if (localStorage.getItem("access_token") != null) {
        if (this.live_train_data.length > 0) {
          axios.get(`${process.env.VUE_APP_API_URL}/api/v1/get-expected-time-ctrlroom`, {
            params: {'station_code': 'D', 'train_numbers': this.live_train_data}
          }).then((response) => {
            if (response.data.status == 1) {
              this.eta_time = response.data.data;

              this.up_train_list = this.up_train_list.map(item1 => {
                const item2 = this.eta_time.find(item => item.train_no === item1.number && item.eim >= 0
                    && item.eim != null);
                return {
                  ...item1,
                  ...item2
                };
              });
              this.down_train_list = this.down_train_list.map(item1 => {
                const item2 = this.eta_time.find(item => item.train_no === item1.number && item.eim >= 0
                    && item.eim != null);
                return {
                  ...item1,
                  ...item2
                };
              });
            }
          });
        }
      }
    },
    getRefreshTrainList(val) {
      if (val.length != 0) {

        // console.warn("Refresh----" + moment().format('HH:mm'));
        this.down_train_list = [];
        this.up_train_list = [];
        val.forEach((item) => {
          if (item.direction_id == 1) {
            if(this.up_search_flag == 0){
              if (moment().format("HH:mm") < '00:30' && moment().format('HH:mm') > '00:00') {
                this.up_train_list.push(item);
              } else {
                if (item.schedule_time >= moment().subtract(15, 'm').format('HH:mm')) {
                  this.up_train_list.push(item);
                }
              }
            }else{
              if(item.number == this.up_search){
                this.up_train_list.push(item);
              }
            }
          } else if (item.direction_id == 2) {
            if(this.down_search_flag == 0){
              if (moment().format("HH:mm") < '0:30' && moment().format('HH:mm') > '0:00') {
                this.down_train_list.push(item);
              } else {
                if (item.schedule_time >= moment().subtract(10, 'm').format('HH:mm')) {
                  this.down_train_list.push(item);
                }
              }
            }else{
              if(item.number == this.down_search){
                this.down_train_list.push(item);
              }
            }

          }
          this.loader = false;
        });

        if (this.flag == 0) {
          this.getLiveLocation();
          this.flag = 1;
        }
        // if(this.live_train_data.length != 0){
        //   this.getLiveLocation();
        // }
      }
    },
    startRefreshingData() {
      // setInterval(() => {
      //   this.getLiveLocation();
      // }, 30000); // Refresh every 30 seconds

      setInterval(() => {
        this.getRefreshTrainList(this.train_list);
      }, 120000);
    },
    beforeDestroy() {
      // Clear interval when component is destroyed
      clearInterval(this.interval);
    },
    handleAlertClose(index) {
      this.snackbars.forEach((element) => {
        if (element.text == index) {
          element.visible = false;
        }
      });
    },
    upClearList(){
      this.up_train_list = [];
      this.train_list.forEach((item) => {
        if (item.direction_id == 1) {
          if(this.up_search_flag == 1){
            if (moment().format("HH:mm") < '00:30' && moment().format('HH:mm') > '00:00') {
              this.up_train_list.push(item);
            } else {
              if (item.schedule_time >= moment().subtract(15, 'm').format('HH:mm')) {
                this.up_train_list.push(item);
              }
            }
          }
        }
      });
      this.loader = false;
      this.getLiveLocation();
    },
    downClearList(){
      this.down_train_list = [];
      this.train_list.forEach((item) => {
        if (item.direction_id == 2) {
          if(this.down_search_flag == 1){
            if (moment().format("HH:mm") < '0:30' && moment().format('HH:mm') > '0:00') {
              this.down_train_list.push(item);
            } else {
              if (item.schedule_time >= moment().subtract(10, 'm').format('HH:mm')) {
                this.down_train_list.push(item);
              }
            }
          }
        }
        this.loader = false;
      });
    }
  },
  mounted() {
    // requestPermission();
    this.flag = 0;
    this.station_id = this.$store.getters.get_user.station_data.station_id;
    this.up_search_flag = 0;
    this.down_search_flag = 0;
    this.initial_up_search = 0;
    this.snackbars = [];
    this.getTrainList();
    this.startRefreshingData();
    this.interval = setInterval(this.getLiveLocation, 30000);
    this.initializeAudio();
  }
}
</script>

<style scoped>

</style>
