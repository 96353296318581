<template>
  <v-row align="center" justify="center" >
    <v-col cols="12" md="9" sm="12" lg="5" sx="12">
          <v-card style="position: relative" class="text-xs-center">
            <br><br>
            <v-img
                :src="require('../assets/images/company_logo.png')"
                width="150"
                height="auto"
                style="margin: 0 auto;"></v-img>
            <v-card-title>
              <v-flex xs12>
                <h2 class="grey--text text--darken-2">Welcome to Yatri</h2>
              </v-flex>

              <v-flex xs12>
                <br>
                <v-form @submit.prevent="validateBeforeSubmit" width="100%" class="login_form">
                  <p v-if="validationErrors.length > 0">
                    <span v-for="error in validationErrors"  :key="error" style="color: red">{{ error }}</span>
                  </p>
                  <v-text-field
                      v-model="email"
                      label="Enter your Email"
                      name="email"
                      browser-autocomplete=off
                      required
                  >
                  </v-text-field>

                  <v-text-field
                      v-model="password"
                      :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="visible ? 'text' : 'password'"
                      placeholder="Enter your password"
                      @click:append-inner="visible = !visible" required
                  ></v-text-field>

                  <v-btn
                      type="submit"
                      class="primary white--text"
                      :loading="is_loading"
                      :disabled="is_loading"
                  >Login</v-btn>
                  <br>
                  <br>
                  <!-- <p>The portal can be viewed in Google Chrome browser</p> -->
                </v-form>
              </v-flex>
              <v-alert :value="true" outline color="error" icon="warning" v-if="error" id="alert-flex">{{ error }}</v-alert>
            </v-card-title>
          </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  name: "LoginPage",
  created() {},
  methods: {
    login() {
      this.is_loading = true;
      let user_data =[this.email,this.password];
      var key = 'a2qT8wXZkL8YTsXT';
      var CryptoJS = require("crypto-js");
      var CryptoJSAesJson = {
        stringify: function (cipherParams) {
          var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
          if (cipherParams.iv) j.iv = cipherParams.iv.toString();
          if (cipherParams.salt) j.s = cipherParams.salt.toString();
          return JSON.stringify(j).replace(/\s/g, '');
        },
        parse: function (jsonStr) {
          var j = JSON.parse(jsonStr);
          var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
          if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
          if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
          return cipherParams;
        }
      }
      var data = CryptoJS.AES.encrypt(JSON.stringify(user_data), key, {format: CryptoJSAesJson}).toString();
      let headers = {
        'app-type': 'yatri-web',
        'api-key' : process.env.VUE_APP_API_KEY
      };

      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/login`, {data:data},{headers})
          .then(response => {
            if (response.data.status == 1) {
              console.warn(response.data);
              this.$store.dispatch('storeAccessToken', response.data.access_token);
              this.$store.dispatch('storeUser', response.data.user)
                  .then(() => {
                    let json_permission = JSON.stringify(response.data.permissions);
                    localStorage.setItem("access_token", response.data.access_token);
                    localStorage.setItem("expiration", response.data.expiration);
                    localStorage.setItem("permissions", json_permission);
                    localStorage.setItem("auth_user", JSON.stringify(response.data.user));
                    this.$store.dispatch('storePermissions', json_permission)
                        .then(() => {
                          this.$parent.$emit('permissions-fetched');
                        })
                    axios.defaults.headers.common['app-type'] = 'web';
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;//this.$store.getters.get_access_token;

                    if(response.data.user.roles[0].name == 'cr_announcer'){
                      // this.$router.push('/announcements');
                      window.location.href = '/';
                    }
                  });
            }else if(response.data.status == 0){
              this.error = response.data.message;
            }
            else {
              this.error = response.data.message;
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.is_loading = false;
          });
    },

    validateBeforeSubmit: function(e) {
      e.preventDefault();
      // this.$validator.validateAll().then(result => {
      //   if (result) {
      //     this.validationErrors = [];
          if (this.email == '') {
            this.validationErrors.push('Employee Code is required.');
          }else if(this.password == ''){
            this.validationErrors.push('Password is required.');
          }else{
            this.login();
          }

      //   }
      //   return false;
      // });
    },
  },

  data() {
    return {
      e1: true,
      is_loading: false,
      email: "",
      password: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid"
      ],
      error: this.$route.query.error,
      validationErrors: [],
      formErrors: {},
      marker: true,
      visible: false,
    };
  },
  mounted() {
    // if(localStorage.getItem('access_token') !== null) {
    // }
  }
};
</script>
<style>
body {
  background: #ecf1f4;
}
</style>
